import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-awesome-modal";
import { ReactPhotoCollage } from "react-photo-collage";
import "./sass/index.sass";

function ModalProject(props) {
  return (
    <div className="modal">
      <Modal
        visible={props.open}
        effect="fadeInUp"
        onClickAway={props.closeModal}
      >
        <button onClick={props.closeModal} className="btn-back">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        
        <div className="contModal">
          <div className="itemLeft">
            <h3 className="subtitle">{props.title}</h3>
            <p>{props.description}</p>
          </div>
          <div className="itemRight">
            <div className="cont-img-slide">
              {props.open && <ReactPhotoCollage
                photos = {props.photos}
                height = {props.imgHeight || []}
                layout = {props.imgLayout || []}
                showNumOfRemainingPhotos
                width = "100%"
              />}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalProject;