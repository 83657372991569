import React from "react";

import Radium, { StyleRoot } from "radium";
import { zoomIn } from "react-animations";

import cover from "./../../assets/images/cover-home.jpg";
import "./sass/style.sass";

const styles = {
  zoomIn: {
    animation: "x 4s",
    animationName: Radium.keyframes(zoomIn, "zoomIn"),
  },
}

class HomePage extends React.Component<any, any> {
  componentDidMount() {
    const pathHome = window.location.pathname;
    const coverProject = document.getElementById("cover");
    const bg = document.getElementById("img-background");
    const app = document.getElementById("app");
    const btn = document.getElementById("btn-iso");
    if (pathHome === "/" && app) {
      app.classList.remove("lumina");
      app.classList.remove("via-maria");
      app.classList.remove("torre-texao");
      app.classList.remove("puerta-del-sol");
      app.classList.remove("ikiga");
    }
    if (coverProject && (pathHome === "/")) {
      coverProject.classList.remove(`cover-two`);
    }
    if (bg && (pathHome === "/")) {
      bg.classList.remove("lumina-cover");
      bg.classList.remove("via-maria-cover");
      bg.classList.remove("torre-texao-cover");
      bg.classList.remove("puerta-del-sol-cover");
      bg.classList.remove("ikiga-cover");
      bg.classList.remove("blur");
    }
    if (btn) {
      btn.style.display = "flex";
      btn.style.flexDirection = "column";
    }
  }

  render() {
    return (
      <div className="wraper home">
        <div className="item left">
        </div>
        <div className="item center" >
          <StyleRoot>
            <div style={styles.zoomIn} >
              <div>
                <div className="clip-animation">
                  <img src={cover} alt="" title=""></img>
                </div></div>
            </div>
          </StyleRoot>
        </div>
        <div className="item right">
        </div>
      </div>
    );
  }
}

export default HomePage;