import casaCayma1 from "./../../assets/images2/architecture/CasaCayma/img1.jpg";
import casaCayma2 from "./../../assets/images2/architecture/CasaCayma/img2.jpg";
import casaCayma3 from "./../../assets/images2/architecture/CasaCayma/img3.jpg";
import casaCayma4 from "./../../assets/images2/architecture/CasaCayma/img4.jpg";
import casaCayma5 from "./../../assets/images2/architecture/CasaCayma/img5.jpg";
import casaCayma6 from "./../../assets/images2/architecture/CasaCayma/img6.jpg";
import casaCayma7 from "./../../assets/images2/architecture/CasaCayma/img7.jpg";
import casaCayma8 from "./../../assets/images2/architecture/CasaCayma/img8.jpg";
import casaCayma9 from "./../../assets/images2/architecture/CasaCayma/img9.jpg";
import casaCayma10 from "./../../assets/images2/architecture/CasaCayma/img10.jpg";
import casaCayma11 from "./../../assets/images2/architecture/CasaCayma/img11.jpg";
import casaCayma12 from "./../../assets/images2/architecture/CasaCayma/img12.jpg";
import casaCayma13 from "./../../assets/images2/architecture/CasaCayma/img13.jpg";
import casaCayma14 from "./../../assets/images2/architecture/CasaCayma/img14.jpg";
import casaCayma15 from "./../../assets/images2/architecture/CasaCayma/img15.jpg";

import edificioLosGuindos1 from "./../../assets/images2/architecture/EdificioLosGuindos/img1.jpg";
import edificioLosGuindos2 from "./../../assets/images2/architecture/EdificioLosGuindos/img2.jpg";
import edificioLosGuindos3 from "./../../assets/images2/architecture/EdificioLosGuindos/img3.jpg";
import edificioLosGuindos4 from "./../../assets/images2/architecture/EdificioLosGuindos/img4.jpg";
import edificioLosGuindos5 from "./../../assets/images2/architecture/EdificioLosGuindos/img5.jpg";
import edificioLosGuindos6 from "./../../assets/images2/architecture/EdificioLosGuindos/img6.jpg";
import edificioLosGuindos7 from "./../../assets/images2/architecture/EdificioLosGuindos/img7.jpg";
import edificioLosGuindos8 from "./../../assets/images2/architecture/EdificioLosGuindos/img8.jpg";
import edificioLosGuindos9 from "./../../assets/images2/architecture/EdificioLosGuindos/img9.jpg";
import edificioLosGuindos10 from "./../../assets/images2/architecture/EdificioLosGuindos/img10.jpg";

import mencionHonrosa1 from "./../../assets/images2/architecture/MencionHonrosaConcurso/img1.jpg";
import mencionHonrosa2 from "./../../assets/images2/architecture/MencionHonrosaConcurso/img2.jpg";
import mencionHonrosa3 from "./../../assets/images2/architecture/MencionHonrosaConcurso/img3.jpg";
import mencionHonrosa4 from "./../../assets/images2/architecture/MencionHonrosaConcurso/img4.jpg";

import tercerPuesto1 from "./../../assets/images2/architecture/TercerPuestoConcurso/img1.jpg";
import tercerPuesto2 from "./../../assets/images2/architecture/TercerPuestoConcurso/img2.jpg";
import tercerPuesto3 from "./../../assets/images2/architecture/TercerPuestoConcurso/img3.jpg";
import tercerPuesto4 from "./../../assets/images2/architecture/TercerPuestoConcurso/img4.jpg";

import casaAzores1 from "./../../assets/images2/architecture/CasaAzores/img1.jpg";
import casaAzores2 from "./../../assets/images2/architecture/CasaAzores/img2.jpg";
import casaAzores3 from "./../../assets/images2/architecture/CasaAzores/img3.jpg";
import casaAzores4 from "./../../assets/images2/architecture/CasaAzores/img4.jpg";
import casaAzores5 from "./../../assets/images2/architecture/CasaAzores/img5.jpg";

import casaSanMarino1 from "./../../assets/images2/architecture/CasaSanMarino/img1.jpg";
import casaSanMarino2 from "./../../assets/images2/architecture/CasaSanMarino/img2.jpg";
import casaSanMarino3 from "./../../assets/images2/architecture/CasaSanMarino/img3.jpg";
import casaSanMarino4 from "./../../assets/images2/architecture/CasaSanMarino/img4.jpg";
import casaSanMarino5 from "./../../assets/images2/architecture/CasaSanMarino/img5.jpg";
import casaSanMarino6 from "./../../assets/images2/architecture/CasaSanMarino/img6.jpg";
import casaSanMarino7 from "./../../assets/images2/architecture/CasaSanMarino/img7.jpg";
import casaSanMarino8 from "./../../assets/images2/architecture/CasaSanMarino/img8.jpg";
import casaSanMarino9 from "./../../assets/images2/architecture/CasaSanMarino/img9.jpg";
import casaSanMarino10 from "./../../assets/images2/architecture/CasaSanMarino/img10.jpg";

import casasCayma1 from "./../../assets/images2/architecture/CasasCayma/img1.jpg";
import casasCayma2 from "./../../assets/images2/architecture/CasasCayma/img2.jpg";
import casasCayma3 from "./../../assets/images2/architecture/CasasCayma/img3.jpg";
import casasCayma4 from "./../../assets/images2/architecture/CasasCayma/img4.jpg";
import casasCayma5 from "./../../assets/images2/architecture/CasasCayma/img5.jpg";
import casasCayma6 from "./../../assets/images2/architecture/CasasCayma/img6.jpg";
import casasCayma7 from "./../../assets/images2/architecture/CasasCayma/img7.jpg";

const itemsGallery = [
  {
    id: 0,
    img: casaCayma1,
    title: "Casa Cayma",
    description:
      "El reto era lograr amplios ambientes rodeados por jardines en un Terreno semitriangular de 300 m2. La respuesta fue generar 4 volúmenes mayoritariamente opacos donde se desarrollan los ambientes de la vivienda como tales, separados por elementos transparentes donde se desarrollan pasillos, jardines interiores, halls. Se logra fluidez espacial, ambientes tibios con la sensación de estar rodeados de sol y vegetación.",
    photos: [
      {
        source: casaCayma1,
      },
      {
        source: casaCayma2,
      },
      {
        source: casaCayma3,
      },
      {
        source: casaCayma4,
      },
      {
        source: casaCayma5,
      },
      {
        source: casaCayma6,
      },
      {
        source: casaCayma7,
      },
      {
        source: casaCayma8,
      },
      {
        source: casaCayma9,
      },
      {
        source: casaCayma10,
      },
      {
        source: casaCayma11,
      },
      {
        source: casaCayma12,
      },
      {
        source: casaCayma13,
      },
      {
        source: casaCayma14,
      },
      {
        source: casaCayma15,
      },
    ],
  },
  {
    id: 1,
    img: edificioLosGuindos8,
    title: "Edifico Los Guindos",
    description:
      "Se busca una composición de volúmenes con expresiones y texturas diferentes. El primero trata de generar virtualidad y ligereza, el segundo es un contensor. Ambos a su vez manejan capas diferentes que buscan profundidad. Se desarrollan distintas tipologías de departamentos que hacen posible la variedad volumétrica resultante.",
    photos: [
      {
        source: edificioLosGuindos8,
      },
      {
        source: edificioLosGuindos9,
      },
      {
        source: edificioLosGuindos10,
      },
      {
        source: edificioLosGuindos7,
      },
      {
        source: edificioLosGuindos1,
      },
      {
        source: edificioLosGuindos2,
      },
      {
        source: edificioLosGuindos3,
      },
      {
        source: edificioLosGuindos4,
      },
      {
        source: edificioLosGuindos5,
      },
      {
        source: edificioLosGuindos6,
      },
    ],
  },
  {
    id: 2,
    img: mencionHonrosa4,
    title: "Mencion Honrosa Concurso",
    description:
      "Concurso “Loft boutique” (mención honrosa). Arquia de Perú lanzó el concurso LOFT BOUTIQUE 2010. Participación de 164 propuestas de todo el mundo. El concurso demandaría un edificio de 30 departamentos tipo loft, de solución innovadora e imagen contemporánea y destacable dentro del distrito de San Isidro-Lima.  El equipo fue conformado por Cesar Alatrista Corrales, Manuel Benítez Acosta y Vladimir Berrios Begazo.",
    photos: [
      {
        source: mencionHonrosa1,
      },
      {
        source: mencionHonrosa2,
      },
      {
        source: mencionHonrosa3,
      },
    ],
  },
  {
    id: 3,
    img: tercerPuesto1,
    title: "Tercer Puesto Concurso",
    description:
      "Concurso “Green 360” (3er puesto). El proyecto consiste en desarrollar lofts de lujo en dos modelos de torres sobre un terreno en pendiente en los cerros de Camacho - Lima. Se presentaron 235 proyectos en los que participaron más de 700 arquitectos de 62 países. La pendiente del terreno se expresó adecuando progresivamente los niveles, resultando lofts con dobles alturas y depresiones. Piscinas en cada loft, cocheras en el piso medio de las torres, son elementos particulares del proyecto. El equipo fue conformado por César Alatrista Corrales, Manuel Benites Acosta y César Hurtado Cervantes.",
    photos: [
      {
        source: tercerPuesto1,
      },
      {
        source: tercerPuesto2,
      },
      {
        source: tercerPuesto3,
      },
      {
        source: tercerPuesto4,
      },
    ],
  },
  {
    id: 4,
    img: casaAzores1,
    title: "Casa en Azores",
    description:
      "El reto de la vivienda era lograr una programación extensa en un terreno relativamente pequeño. De allí que combinar la gran cantidad de área techada, con balcones, volados, celosías y aleros fue determinante para lograr armonía.",
    photos: [
      {
        source: casaAzores1,
      },
      {
        source: casaAzores2,
      },
      {
        source: casaAzores3,
      },
      {
        source: casaAzores4,
      },
      {
        source: casaAzores5,
      },
    ],
  },
  {
    id: 5,
    img: casaSanMarino2,
    title: "Casa San Marino",
    description:
      "Se propone una geometría que permita fluidez de espacio, frescura y nitidez. Se genera un sócalo que protege la casa ante posibles subas de marea, que a su vez, eleva las áreas sociales y sobre estas, únicamente el dormitorio principal. A través de las áreas sociales se experimentará una transparencia que permitirá relacionar los cerros hacia atrás y el mar hacia adelante. Se trabajan ventilaciones cruzadas para generar frescura al interior de los ambientes. Área de terreno 300 m²",
    photos: [
      {
        source: casaSanMarino1,
      },
      {
        source: casaSanMarino2,
      },
      {
        source: casaSanMarino3,
      },
      {
        source: casaSanMarino4,
      },
      {
        source: casaSanMarino5,
      },
      {
        source: casaSanMarino6,
      },
      {
        source: casaSanMarino7,
      },
      {
        source: casaSanMarino8,
      },
      {
        source: casaSanMarino9,
      },
      {
        source: casaSanMarino10,
      },
    ],
  },
  {
    id: 6,
    img: casasCayma1,
    title: "Casas Cayma",
    description:
      "El concepto trata de enlazar la masividad de la arquitectura tradicional en sillar con la plasticidad que tiene este elemento al ser trabajado de forma escultórica. Esta geometría es espaciada y contrastada con una, tecnológicamente más libre, representada por el metal y el hormigón expuesto. Se trabajan 5 viviendas, cada una con un rasgo individual, pero con una expresión fuerte de conjunto.",
    photos: [
      {
        source: casasCayma1,
      },
      {
        source: casasCayma2,
      },
      {
        source: casasCayma3,
      },
      {
        source: casasCayma4,
      },
      {
        source: casasCayma5,
      },
      {
        source: casasCayma6,
      },
      {
        source: casasCayma7,
      },
    ],
  },
];

export default itemsGallery;
