import * as React from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import "./sass/index.sass";

const INITIAL_VALUES = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const contactSchema = Yup.object().shape({
  name: Yup.string().required("Por favor llene este campo"),
  email: Yup.string()
    .email("Por favor escriba una dirección de correo válido")
    .required("Por favor llene este campo"),
  phone: Yup.string().required("Por favor llene este campo"),
});

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function Contact() {
  const classes = useStyles();
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={contactSchema}
      onSubmit={(values, { setSubmitting }) => {
        // setTimeout(() => {
        //   setSubmitting(false);
        //   alert(JSON.stringify(values, null, 2));
        // }, 500);
        window.open(
          "mailto:informes@smartconstructora.com?subject=Contacto%20Smart&body=" +
            encodeURIComponent(
              `${values.message} \n \n ${values.name} \n ${values.email} \n ${values.phone}`
            )
        );
        setSubmitting(false);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <div className="cont-form">
          <Form className={`form ${classes.root}`}>
            <Field
              component={TextField}
              name="name"
              type="name"
              label="NOMBRE"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <Field
              component={TextField}
              name="email"
              type="email"
              label="EMAIL"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <Field
              component={TextField}
              type="phone"
              label="TELÉFONO"
              name="phone"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <Field
              component={TextField}
              type="message"
              label="MENSAJE"
              name="message"
              multiline
              rows={4}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />

            <div className="cont-btn">
              <Button
                variant="contained"
                disabled={isSubmitting}
                onClick={submitForm}
                className="btn-save"
              >
                ENVIAR{" "}
                {isSubmitting && <FontAwesomeIcon icon={faSpinner} pulse />}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
export default Contact;
