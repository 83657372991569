import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Media from "react-responsive";
import { NavLink } from "react-router-dom";
import OutsideAlerter from "../OutsideClick/OutsideClick";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    right: 0,
    left: 0,
    zIndex: 2,
  },
}));

function LateralNav() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <Media minWidth="769px">
        <div className="left__menu">
          <ul>
            <li>
              <NavLink activeClassName="is-active" to="/servicios/arquitectura">
                <span className="name-project">ARQUITECTURA</span>
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/servicios/construccion">
                <span className="name-project">CONSTRUCCIÓN</span>
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/servicios/inmobiliaria">
                <span className="name-project">INMOBILIARIA</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </Media>
      <Media maxWidth="768px">
        <OutsideAlerter hideMenu={handleClickAway}>
          <div className={classes.root}>
            <button
              type="button"
              onClick={handleClick}
              className={`dropdawn ${open ? "open" : ""}`}
            >
              {window.location.pathname.includes("/arquitectura")
                ? "ARQUITECTURA"
                : window.location.pathname.includes("/construccion")
                ? "CONSTRUCCIÓN"
                : window.location.pathname.includes("/inmobiliaria")
                ? "INMOBILIARIA"
                : "ARQUITECTURA"}
            </button>
            {open ? (
              <div className={`nav-dropdawn ${classes.dropdown}`}>
                <div className="left__menu">
                  <ul>
                    <li>
                      <NavLink
                        activeClassName="is-active"
                        to="/servicios/arquitectura"
                      >
                        <span className="name-project">ARQUITECTURA</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="is-active"
                        to="/servicios/construccion"
                      >
                        <span className="name-project">CONSTRUCCIÓN</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="is-active"
                        to="/servicios/inmobiliaria"
                      >
                        <span className="name-project">INMOBILIARIA</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </OutsideAlerter>
      </Media>
    </>
  );
}

export default LateralNav;
