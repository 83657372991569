import React from "react";

import CoverLumina from "../../components/Cover/CoverLumina";
import CoverPuertaDelSol from "../../components/Cover/CoverPuertaDelSol";
import CoverTorreTexao from "../../components/Cover/CoverTorreTexao";
import CoverViaMaria from "../../components/Cover/CoverViaMaria";
import CoverIkiga from "../../components/Cover/CoverIkiga"
import LateralNavProjects from "../../components/LateralNavProjects/LateralNavProjects";

class CoverPage extends React.Component<any, any> {
  render() {
    return (
      <div className="wraper wraper-two wraper-cover">
        <div className="item left">
          <LateralNavProjects />
        </div>
        <div className="item right">
          <div className="right__cont">
            {window.location.pathname.includes("/lumina") ?
              <CoverLumina />
              : window.location.pathname.includes("/via-maria") ?
                <CoverViaMaria />
                : window.location.pathname.includes("/torre-texao") ?
                  <CoverTorreTexao />
                  : window.location.pathname.includes("/puerta-del-sol") ?
                    <CoverPuertaDelSol />
                    : window.location.pathname.includes("/ikiga") ?
                      <CoverIkiga /> : ""
            }
          </div>
        </div>
      </div>
    );
  }
}

export default CoverPage;