import React from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radium, { StyleRoot } from "radium";
import { fadeInRightBig } from "react-animations";
import Img from "react-image";

import buildingConcept from "./../../assets/images/fachada-concept.png";
import "./sass/index.sass";

const styles = {
  fadeInRightBig: {
    animation: "x .5s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
  fadeInRightBig2: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
}

class ConceptPuertaDelSol extends React.Component<any, any> {
  render() {
    return (
      <div className="cont-concept-project">
        <div className="con-text">
          <StyleRoot>
            <div style={styles.fadeInRightBig}>
              <div className="iconPlus" />
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
          </StyleRoot>
        </div>
        <div className="cont-image">
          <StyleRoot>
            <div style={styles.fadeInRightBig2}>
              <Img src={buildingConcept} loader={<div className="loader"><FontAwesomeIcon icon={faSpinner} pulse /></div>} alt="" />
            </div>
          </StyleRoot>
        </div>
      </div>
    );
  }
}

export default ConceptPuertaDelSol;