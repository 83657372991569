import React, { useState, useEffect } from "react";

import Media from "react-responsive";
import ModalIso from "../Modal/Modal";
import DarkButton from "./../DarkButton/DarkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import { ReactComponent as Logo14001B } from "./../../assets/images/Isos/14001/14001_black.svg";
import { ReactComponent as Logo9001B } from "./../../assets/images/Isos/9001/9001_black.svg";
import { ReactComponent as Logo45001B } from "./../../assets/images/Isos/45001/45001_black.svg";
import { ReactComponent as Logo14001W } from "./../../assets/images/Isos/14001/14001_white.svg";
import { ReactComponent as Logo9001W } from "./../../assets/images/Isos/9001/9001_white.svg";
import { ReactComponent as Logo45001W } from "./../../assets/images/Isos/45001/45001_white.svg";

import "./sass/index.sass";

const Footer: React.FC = () => {
  const [visible9001, setVisible9001] = useState<boolean>(false);
  const [visible14001, setVisible14001] = useState<boolean>(false);
  const [visible45001, setVisible45001] = useState<boolean>(false);
  const [isLight, setIsLight] = useState(true);

  useEffect(() => {
    console.log(window.location.pathname);
  }, []);

  const onClose = () => {
    setIsLight((isLight) => !isLight);
  };

  return (
    <div className="footer">
      <Media minWidth="601px">
        <footer>
          <div className="cont-footer">
            <div className="left">
              <h4>&copy;SMART+</h4>
              <div className="left__text">
                <h4>Desarrollado por: INKALABS</h4>
                <DarkButton setIsLight={() => onClose()} />
              </div>
            </div>
            <div className="right">
              <div className="logo-iso" id="btn-iso">
                <button
                  className="logo-button"
                  onClick={() => setVisible14001(true)}
                >
                  {isLight ? <Logo14001B /> : <Logo14001W />}
                </button>
                <button
                  className="logo-button"
                  onClick={() => setVisible45001(true)}
                >
                  {isLight ? <Logo45001B /> : <Logo45001W />}
                </button>
                <button
                  className="logo-button"
                  onClick={() => setVisible9001(true)}
                >
                  {isLight ? <Logo9001B /> : <Logo9001W />}
                </button>
              </div>
            </div>
          </div>
        </footer>
      </Media>
      <Media maxWidth="600px">
        <footer>
          <div className="cont-footer">
            <div className="left">
              <div className="logo-iso" id="btn-iso">
                <button onClick={() => setVisible14001(true)}>
                  {isLight ? <Logo14001B /> : <Logo14001W />}
                </button>
                <button onClick={() => setVisible45001(true)}>
                  {isLight ? <Logo45001B /> : <Logo45001W />}
                </button>
                <button onClick={() => setVisible9001(true)}>
                  {isLight ? <Logo9001B /> : <Logo9001W />}
                </button>
              </div>
            </div>
            <div className="right">
              <DarkButton setIsLight={() => onClose()} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              paddingTop: "10px",
            }}
          >
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/smartconstructora/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://wa.me/51958954743"
              target="_blank"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.facebook.com/smartaqp/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </div>
          <div className="text-reponsive">
            <h4>&copy;SMART+</h4>
            <div className="left__text">
              <h4>Desarrollado por: INKALABS</h4>
            </div>
          </div>
        </footer>
      </Media>
      <ModalIso
        open={visible14001}
        closeModal={() => setVisible14001(false)}
        imgModal={Logo14001B}
        classModal="modal-iso"
        textModal="Somos conscientes del impacto al ambiente, por ello es indispensable y prioritario mantener una adecuada gestión ambiental."
        nextModal={() => setVisible45001(true)}
      />
      <ModalIso
        open={visible45001}
        closeModal={() => setVisible45001(false)}
        imgModal={Logo45001B}
        classModal="modal-iso"
        textModal="Generamos un ambiente de trabajo seguro y saludable."
        prevModal={() => setVisible14001(true)}
        nextModal={() => setVisible9001(true)}
      />
      <ModalIso
        open={visible9001}
        closeModal={() => setVisible9001(false)}
        imgModal={Logo9001B}
        classModal="modal-iso"
        textModal="Todos nuestros procesos constructivos están homologados, garantizando altos estándares de calidad en nuestras construcciones."
        prevModal={() => setVisible45001(true)}
      />
    </div>
  );
};

export default Footer;
