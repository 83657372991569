import React from "react";

import { faChevronLeft , faChevronRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-awesome-modal";

import "./sass/index.sass";

class ModalIso extends React.Component<any, any> {
  render() {
    return (
      <div className={this.props.classModal}>
        <Modal
          visible={this.props.open}
          effect="fadeInUp"
          onClickAway={this.props.closeModal}
        >
          {this.props.prevModal && 
            <button
              onClick={()=>{
                this.props.closeModal();
                this.props.prevModal();
              }}
              className="btn-left"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          }
          {this.props.nextModal &&
            <button
              onClick={()=>{
                this.props.closeModal();
                this.props.nextModal();
              }}
              className="btn-right"
            >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>}
          <button onClick={this.props.closeModal} className="btn-back">
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="contModal">
            <div>
              <this.props.imgModal className="logo-iso" />
              <p>{this.props.textModal}</p>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalIso;