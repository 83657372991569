import React from "react";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import Media from "react-responsive";
import BtnInmobiliaria from "./BtnInmobiliaria";

import { ReactComponent as Logo } from "./../../assets/images/smart-logo.svg";
import "./sass/index.sass";

class Header extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <>
        <header>
          <div className="cont-header">
            {!window.location.pathname.includes("/proyectos") ? (
              window.location.pathname.includes("/nosotros") &&
              window.location.pathname.split("/").length > 2 ? (
                <div className="logo">
                  <Media minWidth="769px">
                    <Link to="/">
                      {window.location.pathname !== "/" && (
                        <div className="btn-smart">
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </div>
                      )}
                      <Logo className="img-logo" />
                    </Link>
                  </Media>
                  <Media maxWidth="768px">
                    <Link to="/nosotros" className="btn-back">
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Link>
                  </Media>
                </div>
              ) : (
                <div className="logo">
                  <Link to="/">
                    {window.location.pathname !== "/" && (
                      <div className="btn-smart">
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </div>
                    )}
                    <Logo className="img-logo" />
                  </Link>
                </div>
              )
            ) : (
              <>
                <Media minWidth="769px">
                  <div>
                    <Link to="/servicios/inmobiliaria/" className="btn-back">
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Link>
                  </div>
                </Media>
                <Media maxWidth="768px">
                  {window.location.pathname.split("/").length > 3 ? (
                    <div>
                      <Link
                        to={
                          "/proyectos/" + window.location.pathname.split("/")[2]
                        }
                        className="btn-back"
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <Link to="/servicios/inmobiliaria" className="btn-back">
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Link>
                    </div>
                  )}
                </Media>
              </>
            )}
            <div className="header-right">
              <div>
                <h4>
                  {window.location.pathname.includes("/proyectos")
                    ? "Proyectos"
                    : window.location.pathname.includes("/nosotros")
                    ? "Nosotros"
                    : window.location.pathname.includes("/contacto")
                    ? "Contacto"
                    : ""}
                </h4>
              </div>
              {window.location.pathname === "/" && <BtnInmobiliaria />}
              <Menu />
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default withRouter(Header);
