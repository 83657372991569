import React from "react";
import Radium, { StyleRoot } from "radium";
import { fadeInRightBig } from "react-animations";
import { fadeInRight } from "react-animations";

import Carousel from "../../components/Carousel/CarouselArchitecture";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./sass/index.sass";

const styles = {
  fadeInRightBig: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
  fadeInRight: {
    animation: "x 3s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInRight2: {
    animation: "x 4s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
}

class Architecture extends React.Component<any, any> {
  
  render() {
    return (
      <div className="cont-about">
        <StyleRoot>
          <h3 className="title" style={styles.fadeInRight}>ARQUITECTURA</h3>
          <div className="cont-text" style={styles.fadeInRight2}>
            <p>Belleza, equilibrio, sobriedad. Funcionalidad.</p>
          </div>
          <Carousel/>
        </StyleRoot>
      </div>
    );
  }
}

export default Architecture;