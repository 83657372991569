import React, { useEffect, useState } from "react";

import ButtonsSocial from "./components/ButtonsSocial/ButtonsSocial";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Preloader from "./components/Preloader/Preloader";
import Routes from "./routes/routes";
import Media from "react-responsive";

import "./assets/sass/style.sass";
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1200);
  }, []);

  return (
    <>
      {loading === false ? (
        <div className="app" id="app">
          <div className="cover" id="cover">
            <div className="img-background" id="img-background"></div>
            <div className="cover__cont">
              <Header />
              <Routes />
              <Footer />
            </div>
          </div>
          <Media minWidth="601px">
            <ButtonsSocial />
          </Media>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default App;
