import React from "react";

import LateralNavProjects from "../../components/LateralNavProjects/LateralNavProjects";
import TypologiesLumina from "../../components/Typologies/TypologiesLumina";
import TypologiesPuertaDelSol from "../../components/Typologies/TypologiesPuertaDelSol";
import TypologiesTorreTexao from "../../components/Typologies/TypologiesTorreTexao";
import TypologiesViaMaria from "../../components/Typologies/TypologiesViaMaria";

class TypologiesProjectPage extends React.Component<any, any> {
  componentDidMount() {
    const path = window.location.pathname.split("/")[2];
    const pathNameProject = window.location.pathname.split("/").length > 3;
    const project = window.location.pathname.includes("/proyectos");
    const concept = window.location.pathname.includes("/concept");
    const coverProject = document.getElementById("cover");
    const bg = document.getElementById("img-background");
    const btn = document.getElementById("btn-iso");
    if (coverProject && project) {
      coverProject.classList.add(`cover-two`);
    }
    if (bg) {
      bg.classList.add(`${path}-cover`);
      if(pathNameProject){
        bg.classList.add("blur");
      }
    }
    if (!concept && coverProject) {
      coverProject.classList.remove(`cover-three`);
    }
    if (btn) {
      btn.style.display = "none";
    }
  }

  render() {
    return (
      <div className="wraper wraper-two">
        <div className="item left">
          <LateralNavProjects />
        </div>
        <div className="item right">
          <div className="right__cont">
            {window.location.pathname.includes("/lumina") ?
              <TypologiesLumina />
              : window.location.pathname.includes("/via-maria") ?
                <TypologiesViaMaria />
                : window.location.pathname.includes("/torre-texao") ?
                  <TypologiesTorreTexao />
                  : window.location.pathname.includes("/puerta-del-sol") ?
                    <TypologiesPuertaDelSol /> : ""
            }
          </div>
        </div>
      </div>
    );
  }
}

export default TypologiesProjectPage;