import React from "react";

import Radium, { StyleRoot } from "radium";
import { fadeInLeft, fadeInRight } from "react-animations";
import Contact from "../../components/Contact/Contact";

const styles = {
  fadeInLeft: {
    animation: "x .3s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInRight"),
  },
  fadeInRight: {
    animation: "x .6s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
}

class ContactPage extends React.Component<any, any> {
  componentDidMount() {
    const pathHome = window.location.pathname;
    const concept = window.location.pathname.includes("/concept");
    const coverProject = document.getElementById("cover");
    const bg = document.getElementById("img-background");
    const app = document.getElementById("app");
    const btn = document.getElementById("btn-iso");
    if (pathHome === "/contacto" && app) {
      app.classList.remove("lumina");
      app.classList.remove("via-maria");
      app.classList.remove("torre-texao");
      app.classList.remove("puerta-del-sol");
      app.classList.remove("ikiga");
    }
    if (coverProject && (pathHome === "/contacto")) {
      coverProject.classList.remove(`cover-two`);
    }
    if (bg && (pathHome === "/contacto")) {
      bg.classList.remove("lumina-cover");
      bg.classList.remove("via-maria-cover");
      bg.classList.remove("torre-texao-cover");
      bg.classList.remove("puerta-del-sol-cover");
      bg.classList.remove("ikiga-cover");
      bg.classList.remove("blur");
    }
    if (!concept && coverProject) {
      coverProject.classList.remove(`cover-three`);
    }
    if (btn) {
      btn.style.display = "none";
    }
  }

  render() {
    return (
      <div className="wraper wraper-three">
        <div className="item left">
          <StyleRoot>
            <div style={styles.fadeInLeft}>
              <h3 className="title">CONTACTO</h3>
              <p>Escríbenos para resolver todas tus dudas sobre nuestros proyectos o servicios</p></div>
          </StyleRoot>
        </div>
        <div className="item right">
          <StyleRoot>
            <div className="right__cont" style={styles.fadeInRight}>
              <Contact />
            </div>
          </StyleRoot>
        </div>
      </div>
    );
  }
}

export default ContactPage;