import React from "react";

import {
  faCaretLeft,
  faCaretRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radium from "radium";
import { fadeInRightBig } from "react-animations";
import Img from "react-image";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ModalProject from "../ModalProject/Modal";
import "./sass/index.sass";

import GalleryPrincipal from "../ItemsGallery/ImagesArchitecture";

const styles = {
  fadeInRightBig: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
};

class Carousel extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(id) {
    this.setState({
      id: id,
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  render() {
    const { title, description, photos } = GalleryPrincipal[this.state.id ?? 0];

    const settings = {
      className: "center",
      centerMode: GalleryPrincipal.length > 1 ? true : false,
      infinite: GalleryPrincipal.length > 1 ? true : false,
      centerPadding: "50px",
      slidesToShow: 3,
      speed: 2000,
      prevArrow: (
        <button>
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
      ),
      nextArrow: (
        <button>
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
      ),
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            centerPadding: GalleryPrincipal.length > 1 ? "0px" : "",
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: GalleryPrincipal.length > 1 ? "0px" : "",
          },
        },
      ],
    };

    return (
      <div>
        <div className="cont-gallery" style={styles.fadeInRightBig}>
          <div>
            <Slider {...settings}>
              {GalleryPrincipal.map(({ id, img, title }, index) => (
                <div
                  className="item"
                  key="index"
                  onClick={() => this.openModal(id)}
                >
                  <div className="cont-img-slide">
                    <Img
                      className="img"
                      src={img}
                      loader={
                        <div className="loader-image">
                          <FontAwesomeIcon icon={faSpinner} pulse />
                        </div>
                      }
                    />
                  </div>
                  <div className="text-on-image">
                    <h6 className="title">{title}</h6>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ModalProject
          open={this.state.visible}
          closeModal={this.closeModal}
          imgLayout={[2, 3]}
          imgHeight={["180px", "180px"]}
          photos={photos}
          title={title}
          description={description}
        />
      </div>
    );
  }
}

export default Carousel;
