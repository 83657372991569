import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Media from "react-responsive";
import { NavLink } from "react-router-dom";
import OutsideAlerter from "../OutsideClick/OutsideClick";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    right: 0,
    left: 0,
    zIndex: 2,
  },
}));

function LateralNavProjects() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <Media minWidth="769px">
        <div className="left__menu lateral-menu">
          <ul>
            {/* <li>
              <NavLink activeClassName="is-active" to={`/proyectos/${window.location.pathname.split("/")[2]}/tipologias`}>
                <span className="name-project">Tipologías</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                activeClassName="is-active"
                to={`/proyectos/${
                  window.location.pathname.split("/")[2]
                }/galeria`}
              >
                <span className="name-project">Galería</span>
              </NavLink>
            </li>
            {window.location.pathname.includes("/ikiga") && (
              <li>
                <NavLink
                  activeClassName="is-active"
                  to={`/proyectos/${
                    window.location.pathname.split("/")[2]
                  }/brochure`}
                >
                  <span className="name-project">Brochure</span>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                activeClassName="is-active"
                to={`/proyectos/${
                  window.location.pathname.split("/")[2]
                }/concepto`}
              >
                <span className="name-project">Concepto</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </Media>
      <Media maxWidth="768px">
        {window.location.pathname.split("/").length > 3 ? (
          <OutsideAlerter hideMenu={handleClickAway}>
            <div className={classes.root}>
              <button
                type="button"
                onClick={handleClick}
                className={`dropdawn ${open ? "open" : ""}`}
              >
                {window.location.pathname.includes("/ubicacion")
                  ? "Ubicación"
                  : window.location.pathname.includes("/concepto")
                  ? "Concepto"
                  : window.location.pathname.includes("/tipologias")
                  ? "Tipologías"
                  : window.location.pathname.includes("/galeria")
                  ? "Galería"
                  : window.location.pathname.includes("/brochure")
                  ? "Brochure"
                  : ""}
              </button>
              {open ? (
                <div className={`nav-dropdawn ${classes.dropdown}`}>
                  <div className="left__menu">
                    <ul>
                      {/* <li>
                        <NavLink
                          activeClassName="is-active"
                          to={`/proyectos/${
                            window.location.pathname.split("/")[2]
                          }/ubicacion`}
                        >
                          <span className="name-project">Ubicación</span>
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          activeClassName="is-active"
                          to={`/proyectos/${
                            window.location.pathname.split("/")[2]
                          }/galeria`}
                        >
                          <span className="name-project">Galería</span>
                        </NavLink>
                      </li>
                      {window.location.pathname.includes("/ikiga") && (
                        <li>
                          <NavLink
                            activeClassName="is-active"
                            to={`/proyectos/${
                              window.location.pathname.split("/")[2]
                            }/brochure`}
                          >
                            <span className="name-project">Brochure</span>
                          </NavLink>
                        </li>
                      )}
                      <li>
                        <NavLink
                          activeClassName="is-active"
                          to={`/proyectos/${
                            window.location.pathname.split("/")[2]
                          }/concepto`}
                        >
                          <span className="name-project">Concepto</span>
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink activeClassName="is-active" to={`/proyectos/${window.location.pathname.split("/")[2]}/tipologias`}>
                          <span className="name-project">Tipologías</span>
                        </NavLink>
                      </li> */}
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          </OutsideAlerter>
        ) : (
          <div className="left__menu lateral-menu">
            <ul>
              {/* <li>
                <NavLink
                  activeClassName="is-active"
                  to={`/proyectos/${
                    window.location.pathname.split("/")[2]
                  }/ubicacion`}
                >
                  <span className="name-project">Ubicación</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  activeClassName="is-active"
                  to={`/proyectos/${
                    window.location.pathname.split("/")[2]
                  }/galeria`}
                >
                  <span className="name-project">Galería</span>
                </NavLink>
              </li>
              {window.location.pathname.includes("/ikiga") && (
                <li>
                  <NavLink
                    activeClassName="is-active"
                    to={`/proyectos/${
                      window.location.pathname.split("/")[2]
                    }/brochure`}
                  >
                    <span className="name-project">Brochure</span>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  activeClassName="is-active"
                  to={`/proyectos/${
                    window.location.pathname.split("/")[2]
                  }/concepto`}
                >
                  <span className="name-project">Concepto</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink activeClassName="is-active" to={`/proyectos/${window.location.pathname.split("/")[2]}/tipologias`}>
                  <span className="name-project">Tipologías</span>
                </NavLink>
              </li> */}
            </ul>
          </div>
        )}
      </Media>
    </>
  );
}

export default LateralNavProjects;
