import React from "react";

import {
  faCaretLeft,
  faCaretRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radium, { StyleRoot } from "radium";
import { fadeInRightBig } from "react-animations";
import Img from "react-image";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import galleryFour from "./../../assets/images/PuertaDelSol/gallery-four.jpg";
import galleryOne from "./../../assets/images/PuertaDelSol/gallery-one.jpg";
import galleryThree from "./../../assets/images/PuertaDelSol/gallery-three.jpg";
import galleryTwo from "./../../assets/images/PuertaDelSol/gallery-two.jpg";
import "./sass/index.sass";

const itemsGallery = [
  {
    img: galleryOne,
    title: "LOREM IPSUM",
    description:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    img: galleryTwo,
    title: "LOREM IPSUM",
    description:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    img: galleryThree,
    title: "LOREM IPSUM",
    description:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    img: galleryFour,
    title: "LOREM IPSUM",
    description:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
];

const styles = {
  fadeInRightBig: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
};

class Gallery extends React.Component<any, any> {
  render() {
    const settings = {
      className: "center",
      centerMode: itemsGallery.length > 1 ? true : false,
      infinite: itemsGallery.length > 1 ? true : false,
      centerPadding: itemsGallery.length > 1 ? "75px" : "",
      slidesToShow: 1,
      speed: 1500,
      prevArrow: (
        <button>
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
      ),
      nextArrow: (
        <button>
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
      ),
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            centerPadding: itemsGallery.length > 1 ? "0px" : "",
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: itemsGallery.length > 1 ? "0px" : "",
          },
        },
      ],
    };

    return (
      <StyleRoot>
        <div className="cont-about">
          <div className="cont-gallery" style={styles.fadeInRightBig}>
            <Slider {...settings}>
              {itemsGallery.map(({ img, title, description }, index) => (
                <div className="item" key={index}>
                  <div className="cont-img-slide">
                    <Img
                      src={img}
                      alt=""
                      loader={
                        <div className="loader-image">
                          <FontAwesomeIcon icon={faSpinner} pulse />
                        </div>
                      }
                    />
                  </div>
                  <div className="caption">
                    <h5 className="title-caption">{title}</h5>
                    <p>{description}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </StyleRoot>
    );
  }
}

export default Gallery;
