import React from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radium, { StyleRoot } from "radium";
import { fadeInRightBig } from "react-animations";
import Img from "react-image";

import buildingConcept from "./../../assets/images/building-via-maria.png";
import "./sass/index.sass";

const styles = {
  fadeInRightBig: {
    animation: "x .5s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
  fadeInRightBig2: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
};

class ConceptViaMaria extends React.Component<any, any> {
  render() {
    return (
      <div className="cont-concept-project">
        <div className="con-text">
          <StyleRoot>
            <div style={styles.fadeInRightBig}>
              <div className="iconPlus" />
              <p>
                Conceptualmente, cada departamento es un muelle que se proyecta
                al mar. La sala, comedor y cocina componen un solo espacio que
                va de lado a lado del edificio, la visual es permanente hacia el
                mar y a los cerros por atrás. Ubicación: Mejia, Arequipa.
              </p>
            </div>
          </StyleRoot>
        </div>
        <div className="cont-image">
          <StyleRoot>
            <div style={styles.fadeInRightBig2}>
              <Img
                src={buildingConcept}
                loader={
                  <div className="loader">
                    <FontAwesomeIcon icon={faSpinner} pulse />
                  </div>
                }
                alt=""
              />
            </div>
          </StyleRoot>
        </div>
      </div>
    );
  }
}

export default ConceptViaMaria;
