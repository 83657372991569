import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Media from "react-responsive";
import { NavLink } from "react-router-dom";
import OutsideAlerter from "../OutsideClick/OutsideClick";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    right: 0,
    left: 0,
    zIndex: 2,
  },
}));

function LateralNav() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <Media minWidth="769px">
        <div className="left__menu">
          <ul>
            <li>
              <NavLink activeClassName="is-active" to="/nosotros/vission-and-mission/">
                <span className="name-project">Visión - Misión</span>
                <span className="subtitle">Pensamos en Grande</span>
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/nosotros/quienes-somos">
                <span className="name-project">¿Quiénes Somos?</span>
                <span className="subtitle">Conoce nuestra historia</span>
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/nosotros/nuetros-pilares">
                <span className="name-project">Nuestros Pilares</span>
                <span className="subtitle">Apasionados por la construcción</span>
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/nosotros/nuestra-trayectoria">
                <span className="name-project">Nuestra Trayectoria</span>
                <span className="subtitle">Nos respalda LA EXPERIENCIA</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </Media>
      <Media maxWidth="768px">
        <OutsideAlerter hideMenu={handleClickAway}>
          <div className={classes.root}>
            <button type="button" onClick={handleClick} className={`dropdawn ${open ? "open" : ""}`}>
              {window.location.pathname.includes("/vission-and-mission") ? "Visión - Misión" : window.location.pathname.includes("/quienes-somos") ? "¿Quiénes Somos?" : window.location.pathname.includes("/nuetros-pilares") ? "Nuestros Pilares" : window.location.pathname.includes("/nuestra-trayectoria") ? "Nuestra Trayectoria" : "Visión - Misión"}
            </button>
            {open ? (
              <div className={`nav-dropdawn ${classes.dropdown}`}>
                <div className="left__menu">
                  <ul>
                    <li>
                      <NavLink activeClassName="is-active" to="/nosotros/vission-and-mission/">
                        <span className="name-project">Visión - Misión</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="is-active" to="/nosotros/quienes-somos">
                        <span className="name-project">¿Quiénes Somos?</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="is-active" to="/nosotros/nuetros-pilares">
                        <span className="name-project">Nuestros Pilares</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="is-active" to="/nosotros/nuestra-trayectoria">
                        <span className="name-project">Nuestra Trayectoria</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </div
        ></OutsideAlerter>
      </Media>
    </>
  );
}

export default LateralNav;