import React from "react";

import {
  faFacebookF,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Media from "react-responsive";

import "./sass/index.sass";

function ButtonsSocial() {
  return (
    <div className="sticky-share-container">
      <div className="cont">
        <ul>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/smartconstructora/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://wa.me/51958954743"
              target="_blank"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.facebook.com/smartaqp/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ButtonsSocial;
