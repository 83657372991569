import React from "react";

import BrochureIkiga from "../../components/Brochure/BrochureIkiga";
import LateralNavProjects from "../../components/LateralNavProjects/LateralNavProjects";

class BrochureProjectPage extends React.Component<any, any> {
  render() {
    return (
      <div className="wraper wraper-two">
        <div className="item left" style={{ padding: "10px" }}>
          <LateralNavProjects />
        </div>
        <div className="item right">
          <div className="right__cont">
            {window.location.pathname.includes("/ikiga") ? (
              <BrochureIkiga />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BrochureProjectPage;
