import React from "react";

import LateralNav from "../../components/LateralNav/LateralNav";
import OurTrajectory from "../../components/OurTrajectory/OurTrajectory";

class OurTrajectoryPage extends React.Component<any, any> {
  componentDidMount() {
    const pathHome = window.location.pathname;
    const concept = window.location.pathname.includes("/concept");
    const coverProject = document.getElementById("cover");
    const bg = document.getElementById("img-background");
    const app = document.getElementById("app");
    const btn = document.getElementById("btn-iso");
    if (pathHome === "/nuestra-trayectoria" && app) {
      app.classList.remove("lumina");
      app.classList.remove("via-maria");
      app.classList.remove("torre-texao");
      app.classList.remove("puerta-del-sol");
      app.classList.remove("ikiga");
    }
    if (coverProject && (pathHome === "/nuestra-trayectoria")) {
      coverProject.classList.remove(`cover-two`);
    }
    if (bg && (pathHome === "/nuestra-trayectoria")) {
      bg.classList.remove("lumina-cover");
      bg.classList.remove("via-maria-cover");
      bg.classList.remove("torre-texao-cover");
      bg.classList.remove("puerta-del-sol-cover");
      bg.classList.remove("ikiga-cover");
      bg.classList.remove("blur");
    }
    if (!concept && coverProject) {
      coverProject.classList.remove(`cover-three`);
    }
    if (btn) {
      btn.style.display = "none";
    }
  }
  render() {
    return (
      <div className="wraper wraper-two">
        <div className="item left">
          <LateralNav />
        </div>
        <div className="item right">
          <div className="right__cont">
            <OurTrajectory />
          </div>
        </div>
      </div>
    );
  }
}

export default OurTrajectoryPage;