import React from "react";

import Radium, { StyleRoot } from "radium";
import { fadeInRight } from "react-animations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import "./sass/index.sass";

const styles = {
  fadeInRight: {
    animation: "x 2.4s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInRight2: {
    animation: "x 4.2s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
}

class VisionAndMission extends React.Component<any, any> {
  render() {
    return (
      <div className="cont-mv">
        <StyleRoot>
          <div className="flex">
            <div className="mission block" style={styles.fadeInRight}>
              <h3 className="title">MISIÓN</h3>
              <p>Arquitectura y construcción de alta calidad. </p>
            </div>
            <div className="vission block" style={styles.fadeInRight2}>
              <h3 className="title">VISIÓN</h3>
              <p>Imponer un nuevo standard de calidad, confort, belleza y responsabilidad social a través de nuestras obras.</p>
            </div>
          </div>
          <div className="file" style={styles.fadeInRight2}>
            <p>Política del sistema <br/> integrado de gestión </p>
            <a href="/files/PoliticaDelSIG.pdf"  download="Politica del SIG"  target="_blank">
              <FontAwesomeIcon icon={faFilePdf} style={{ color: "grey" }} size="2x"/>
            </a>
          </div>
        </StyleRoot>
      </div>
    );
  }
}

export default VisionAndMission;