import React from "react";

import {
  faCaretLeft,
  faCaretRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radium from "radium";
import { fadeInRightBig } from "react-animations";
import { Link } from "react-router-dom";
import Img from "react-image";
import Slider from "react-slick";
import Media from "react-responsive";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import inmobiliariaViamarina from "./../../assets/images/inmobiliaria-viamarina.png";
import inmobiliariaTorretexao from "./../../assets/images/inmobiliaria-torretexao.png";
import inmobiliariaLumina from "./../../assets/images/inmobiliaria-lumina.png";
import inmobiliariaIkiga from "./../../assets/images/inmobiliaria-ikiga-flag.png";
import "./sass/index.sass";

const itemsGallery = [
  {
    img: inmobiliariaIkiga,
    link: "ikiga",
    description:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    img: inmobiliariaLumina,
    link: "lumina",
    description:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    img: inmobiliariaTorretexao,
    link: "torre-texao",
    description:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    img: inmobiliariaViamarina,
    link: "via-maria",
    description:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
];

const styles = {
  height: "200px !important",
  fadeInRightBig: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
};

const Carousel = () => {
  const settings = {
    className: "center",
    centerMode: false,
    infinite: itemsGallery.length > 1 ? true : false,
    centerPadding: "50px",
    slidesToShow: 3,
    speed: 2000,
    prevArrow: (
      <button>
        <FontAwesomeIcon icon={faCaretLeft} />
      </button>
    ),
    nextArrow: (
      <button>
        <FontAwesomeIcon icon={faCaretRight} />
      </button>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerPadding: itemsGallery.length > 1 ? "0px" : "",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: itemsGallery.length > 1 ? "0px" : "",
        },
      },
    ],
  };

  return (
    <div>
      <div
        className="cont-gallery"
        style={{
          ...styles.fadeInRightBig,
          height: "25vw",
        }}
      >
        <div>
          <Slider {...settings}>
            {itemsGallery.map(({ img, link }, index) => (
              <div className="item" key="index">
                <div className="cont-img-slide2">
                  <Media minWidth="1200px">
                    <Link to={`/proyectos/${link}/galeria`}>
                      <Img
                        src={img}
                        loader={
                          <div className="loader-image">
                            <FontAwesomeIcon icon={faSpinner} pulse />
                          </div>
                        }
                      />
                    </Link>
                  </Media>
                  <Media maxWidth="1200px" minWidth="450px">
                    <Link to={`/proyectos/${link}/galeria`}>
                      <Img
                        src={img}
                        style={{ height: "50vh" }}
                        loader={
                          <div className="loader-image">
                            <FontAwesomeIcon icon={faSpinner} pulse />
                          </div>
                        }
                      />
                    </Link>
                  </Media>
                  <Media maxWidth="450px">
                    <Link to={`/proyectos/${link}`}>
                      <Img
                        src={img}
                        style={{ height: "40vw" }}
                        loader={
                          <div className="loader-image">
                            <FontAwesomeIcon icon={faSpinner} pulse />
                          </div>
                        }
                      />
                    </Link>
                  </Media>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
