import React from "react";

import Radium, { StyleRoot } from "radium";
import { fadeInRight } from "react-animations";

import people from "./../../assets/images/about-us.jpg";
import { ReactComponent as Logo } from "./../../assets/images/smart-logo.svg";
import "./sass/index.sass";

const styles = {
  fadeInRight: {
    animation: "x 4.6s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInRight2: {
    animation: "x 5.4s",
    width: "50%",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInRight3: {
    animation: "x 6.2s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
};

class AboutUs extends React.Component<any, any> {
  render() {
    return (
      <div className="cont-about">
        <StyleRoot style={{ display: "flex", flexDirection: "column" }}>
          <div className="cont-logo" style={styles.fadeInRight}>
            <Logo className="" />
          </div>
          <div className="cont-text" style={styles.fadeInRight2}>
            <p>
              Smart nace hace más de 15 años como una empresa inspirada en
              cambiar la tendencia de diseño, arquitectura y construcción en
              general.
            </p>
            <p>
              Ofreciendo la más alta propuesta de valor, basada en altos
              estándares de confort, calidad, belleza y responsabilidad social
              en nuestras edificaciones.
            </p>
          </div>
          {/* <div className="cont-img" style={styles.fadeInRight3}>
            <img src={people} className="img-about" alt="" />
          </div> */}
        </StyleRoot>
      </div>
    );
  }
}

export default AboutUs;
