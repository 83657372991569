import { useState, useEffect } from "react";

import {
  faCaretLeft,
  faCaretRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radium, { StyleRoot } from "radium";
import { fadeInRightBig } from "react-animations";
import Img from "react-image";
import Slider from "react-slick";
import Carousel, { Modal, ModalGateway } from "react-images";
import Media, { useMediaQuery } from "react-responsive";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import lumina1 from "./../../assets/images2/realEstate/lumina/img1.jpg";
import lumina2 from "./../../assets/images2/realEstate/lumina/img2.jpg";
import lumina3 from "./../../assets/images2/realEstate/lumina/img3.jpg";
import lumina4 from "./../../assets/images2/realEstate/lumina/img4.jpg";
import lumina5 from "./../../assets/images2/realEstate/lumina/img5.jpg";
import lumina6 from "./../../assets/images2/realEstate/lumina/img6.jpg";
import lumina7 from "./../../assets/images2/realEstate/lumina/img7.jpg";
import lumina8 from "./../../assets/images2/realEstate/lumina/img8.jpg";
import lumina9 from "./../../assets/images2/realEstate/lumina/img9.jpg";
import lumina10 from "./../../assets/images2/realEstate/lumina/img10.jpg";
import lumina11 from "./../../assets/images2/realEstate/lumina/img11.jpg";
import lumina12 from "./../../assets/images2/realEstate/lumina/img12.jpg";
import lumina13 from "./../../assets/images2/realEstate/lumina/img13.jpg";
import lumina14 from "./../../assets/images2/realEstate/lumina/img14.jpg";
import lumina15 from "./../../assets/images2/realEstate/lumina/img15.jpg";
import lumina16 from "./../../assets/images2/realEstate/lumina/img16.jpg";

import "./sass/index.sass";

const imagesLumina = [
  lumina1,
  lumina2,
  lumina3,
  lumina4,
  lumina5,
  lumina6,
  lumina7,
  lumina8,
  lumina9,
  lumina10,
  lumina11,
  lumina12,
  lumina13,
  lumina14,
  lumina15,
  lumina16,
];

const widths = [
  910, 910, 950, 1000, 480, 1000, 1000, 1100, 480, 1000, 900, 920, 950, 1000,
  900, 980,
];

const styles = {
  fadeInRightBig: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
};

const Gallery = () => {
  const [itemsGallery, setItemsGallery] = useState<
    { img: string; width: string }[]
  >([]);
  const [itemsModal, setItemsModal] = useState<
    { source: string; id: number }[]
  >([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    const ratio = isMobile ? 0.27 : isTablet ? 0.55 : 0.69;
    const gallery = imagesLumina.map((v, index) => {
      return {
        img: v,
        width: `${Math.floor(widths[index] * ratio)}px`,
      };
    });

    const modal = imagesLumina.map((v, index) => {
      return {
        source: v,
        id: index,
      };
    });

    setItemsGallery(gallery);
    setItemsModal(modal);
  }, []);

  const settings = {
    className: "center",
    centerMode: itemsGallery.length > 1 ? true : false,
    infinite: itemsGallery.length > 1 ? true : false,
    centerPadding: itemsGallery.length > 1 ? "75px" : "",
    slidesToShow: 1,
    speed: 1500,
    variableWidth: true,
    prevArrow: (
      <button>
        <FontAwesomeIcon icon={faCaretLeft} />
      </button>
    ),
    nextArrow: (
      <button>
        <FontAwesomeIcon icon={faCaretRight} />
      </button>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerPadding: itemsGallery.length > 1 ? "0px" : "",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: itemsGallery.length > 1 ? "0px" : "",
        },
      },
    ],
  };

  return (
    <StyleRoot>
      <div className="cont-about">
        <div className="cont-gallery" style={styles.fadeInRightBig}>
          <Slider {...settings}>
            {itemsGallery.map(({ img, width }, index) => (
              <div
                className="item"
                key={index}
                style={{ width: width }}
                onClick={() => {
                  setCurrentIndex(index);
                  setModalIsOpen(true);
                }}
              >
                <div className="cont-img-slide">
                  <Img
                    src={img}
                    alt=""
                    loader={
                      <div className="loader-image">
                        <FontAwesomeIcon icon={faSpinner} pulse />
                      </div>
                    }
                  />
                </div>
              </div>
            ))}
          </Slider>
          <Media maxWidth="768px">
            <ModalGateway>
              {modalIsOpen ? (
                <Modal onClose={() => setModalIsOpen(false)}>
                  <Carousel views={itemsModal} currentIndex={currentIndex} />
                </Modal>
              ) : null}
            </ModalGateway>
          </Media>
        </div>
      </div>
    </StyleRoot>
  );
};

export default Gallery;
