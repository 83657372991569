import React from "react";
import "./sass/index.sass";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radium, { StyleRoot } from "radium";
import { fadeOut } from "react-animations";

const styles = {
  fadeOut: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeOut, "fadeOut"),
  },
}

class Preloader extends React.Component<any, any> {
  render() {
    return (
      <StyleRoot>
        <div className="preloader">
          <div className="cont-preloader">
            <div style={styles.fadeOut}>
              <FontAwesomeIcon icon={faPlus} pulse />
            </div>
          </div>
        </div>
      </StyleRoot>
    );
  }
}

export default Preloader;