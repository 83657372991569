import * as React from "react";
import { Route, Switch } from "react-router-dom";
import AboutUsPage from "../views/AboutUs/AboutUsPage";
import ConceptProjectPage from "../views/ConceptProject/ConceptProjectPage";
import ContactPage from "../views/Contact/ContactPage";
import CoverPage from "../views/CoverPage/CoverPage";
import GalleryProjectPage from "../views/GalleryProject/GalleryProjectPage";
import HomePage from "../views/Home/HomePage";
import LocationProjectPage from "../views/LocationProject/LocationProjectPage";
import NotFound from "../views/NotFound/NotFoundPage";
import OurPillarsPage from "../views/OurPillars/OurPillarsPage";
import OurTrajectoryPage from "../views/OurTrajectory/OurTrajectoryPage";
import Thankyou from "../views/Thankyou/ThankyouPage";
import TypologiesProjectPage from "../views/TypologiesProject/TypologiesProjectPage";
import VisionAndMissionPage from "../views/VisionAndMission/VisionAndMissionPage";
import ArchitecturePage from "../views/Architecture/ArchitecturePage";
import ConstructionPage from "../views/Construction/ConstructionPage";
import RealEstatePage from "../views/RealEstate/RealEstatePage";
import BrochureProjectPage from "../views/BrochureProject/BrochureProjectPage";
import UsPage from "../views/UsPage/UsPage";

export const baseUrl = "/";
export const aboutUs = `${baseUrl}nosotros/quienes-somos/`;
export const vissionAndMission = `${baseUrl}nosotros/vision-y-mision/`;
export const ourPillar = `${baseUrl}nosotros/nuetros-pilares/`;
export const ourTrajectory = `${baseUrl}nosotros/nuestra-trayectoria/`;
export const contact = `${baseUrl}contacto/`;
export const projects = `${baseUrl}proyectos/:project_name/`;
export const projectsLocation = `${baseUrl}proyectos/:project_name/ubicacion/`;
export const projectsGallery = `${baseUrl}proyectos/:project_name/galeria/`;
export const projectsTypologies = `${baseUrl}proyectos/:project_name/tipologias/`;
export const projectsConcept = `${baseUrl}proyectos/:project_name/concepto/`;
export const projectsBrochure = `${baseUrl}proyectos/:project_name/brochure/`;
export const us = `${baseUrl}nosotros/`;
export const thankyou = `${baseUrl}gracias/`;
export const services = `${baseUrl}servicios/`;
export const architecture = `${baseUrl}servicios/arquitectura/`;
export const construction = `${baseUrl}servicios/construccion/`;
export const realEstate = `${baseUrl}servicios/inmobiliaria/`;

export const UsRoutes: React.FC = () => (
  <Switch>
    <Route exact path={us} component={UsPage} />
    <Route path={aboutUs} component={AboutUsPage} />
    <Route path={vissionAndMission} component={VisionAndMissionPage}></Route>
    <Route path={ourPillar} component={OurPillarsPage}></Route>
    <Route path={ourTrajectory} component={OurTrajectoryPage}></Route>
    <Route component={VisionAndMissionPage} />
    <Route component={NotFound} />
  </Switch>
);

export const ProjectsRoutes: React.FC = () => (
  <Switch>
    <Route exact path={projects} component={GalleryProjectPage} />
    <Route path={projectsLocation} component={LocationProjectPage} />
    <Route path={projectsGallery} component={GalleryProjectPage} />
    <Route path={projectsTypologies} component={TypologiesProjectPage} />
    <Route path={projectsConcept} component={ConceptProjectPage} />
    <Route path={projectsBrochure} component={BrochureProjectPage} />
    <Route component={CoverPage} />
    <Route component={NotFound} />
  </Switch>
);

export const ServicesRoutes: React.FC = () => (
  <Switch>
    <Route exact path={services} component={ArchitecturePage} />
    <Route path={architecture} component={ArchitecturePage} />
    <Route path={construction} component={ConstructionPage} />
    <Route path={realEstate} component={RealEstatePage} />
    <Route component={ArchitecturePage} />
    <Route component={NotFound} />
  </Switch>
);

export const Routes: React.FC = () => (
  <Switch>
    <Route exact path={baseUrl} component={HomePage} />
    <Route path={contact} component={ContactPage}></Route>
    <Route path={us} component={UsRoutes}></Route>
    <Route path={projects} component={ProjectsRoutes}></Route>
    <Route path={thankyou} component={Thankyou}></Route>
    <Route path={services} component={ServicesRoutes}></Route>
    <Route component={NotFound} />
  </Switch>
);
export default Routes;
