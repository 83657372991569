import React from "react";

import { Link } from "react-router-dom";

import "./sass/style.sass";

class NotFound extends React.Component<any, any> {
  render() {
    return (
      <div className="wraper wraper-not-found">
        <div className="cont-not-found">
          <div>
            <h1>404</h1>
            <h4>¡ LO SENTIMOS !</h4>
            <h4>Página no encontrada</h4>
            <Link to="/" className="btn">REGRESAR</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;