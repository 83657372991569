import React from "react";

import Radium, { StyleRoot } from "radium";
import { fadeInRight } from "react-animations";

import { ReactComponent as Arquitectura } from "./../../assets/images/arquitectura.svg";
import { ReactComponent as Calidad } from "./../../assets/images/calidad.svg";
import { ReactComponent as Confort } from "./../../assets/images/confort.svg";
import { ReactComponent as Experiencia } from "./../../assets/images/experiencia.svg";
import ComponentPopover from "./../ComponentPopover/ComponentPopover";
import "./sass/index.sass";

const styles = {
  fadeInRight: {
    animation: "x 3.0s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInRight2: {
    animation: "x 3.8s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInRight3: {
    animation: "x 4.6s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInRight4: {
    animation: "x 5.4s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
};

function OurPillars() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="cont-pilares">
      <StyleRoot>
        <div className="flex">
          <div
            className="pilar"
            style={styles.fadeInRight}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <ComponentPopover
              anio={
                <div className="icon-pilar">
                  <Arquitectura />
                </div>
              }
              description={"Belleza, equilibrio, sobriedad, funcionalidad."}
              positionva="top"
              positionvt="bottom"
              idp="p-1"
            />
            <div className="name-pilar">
              <h4>ARQUITECTURA</h4>
            </div>
          </div>
          <div className="pilar" style={styles.fadeInRight2}>
            <ComponentPopover
              anio={
                <div className="icon-pilar">
                  <Experiencia />
                </div>
              }
              description={
                <>
                  25000 m<sup>2</sup> construidos. Proyectos inmobiliarios,
                  comercios, salud, almacenes, naves industriales, etc.
                </>
              }
              positionva="top"
              positionvt="bottom"
              idp="p-1"
            />
            <div className="name-pilar">
              <h4>EXPERIENCIA</h4>
            </div>
          </div>
          <div className="pilar" style={styles.fadeInRight3}>
            <ComponentPopover
              anio={
                <div className="icon-pilar">
                  <Calidad />
                </div>
              }
              description={"ISO 9001. ISO 14001. ISO 45001."}
              positionva="top"
              positionvt="bottom"
              idp="p-1"
            />
            <div className="name-pilar">
              <h4>CALIDAD</h4>
            </div>
          </div>
          <div className="pilar" style={styles.fadeInRight4}>
            <ComponentPopover
              anio={
                <div className="icon-pilar">
                  <Confort />
                </div>
              }
              description={
                "Calidad de vivienda, diseño funcional, tecnología, vanguardia."
              }
              positionva="top"
              positionvt="bottom"
              idp="p-1"
            />
            <div className="name-pilar">
              <h4>CONFORT</h4>
            </div>
          </div>
        </div>
      </StyleRoot>
    </div>
  );
}

export default OurPillars;
