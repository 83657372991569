import React from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radium, { StyleRoot } from "radium";
import { fadeInRightBig } from "react-animations";
import Img from "react-image";

import buildingConcept from "./../../assets/images/fachada-concept.png";
import "./sass/index.sass";

const styles = {
  fadeInRightBig: {
    animation: "x .5s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
  fadeInRightBig2: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
};

class ConceptLumina extends React.Component<any, any> {
  render() {
    return (
      <div className="cont-concept-project">
        <div className="con-text">
          <StyleRoot>
            <div style={styles.fadeInRightBig}>
              <div className="iconPlus" />
              <p>
                {/* Tranquilidad, privacidad y un excelente clima para sus espacios,
                gracias a un para sus espacios, gracias a un en cada uno de los
                departamentos. no hay cambios de temperatura, evita el polvo, el
                ruido, agua del exterior y mejora la calidad de vivienda en un
                40%. */}
                Conceptualmente es una roca de cuarzo sobresaliente en el
                terreno. Un volumen sobrio y angulado que absorbe y refleja la
                luz del sol, un sócalo que lo sujeta a la tierra. Las caras de
                este volumen tratan de recomponer las perspectivas de la
                urbanización a la que pertenece el edificio, además de ampliar
                la sensación desde el parque del frente.
              </p>
            </div>
          </StyleRoot>
        </div>
        <div className="cont-image">
          <StyleRoot>
            <div style={styles.fadeInRightBig2}>
              <Img
                src={buildingConcept}
                loader={
                  <div className="loader">
                    <FontAwesomeIcon icon={faSpinner} pulse />
                  </div>
                }
                alt=""
              />
            </div>
          </StyleRoot>
        </div>
      </div>
    );
  }
}

export default ConceptLumina;
