import React from "react";

import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import planoOne from "./../../assets/images/plano-1.png";
import planoTwo from "./../../assets/images/plano-1.png";
import "./sass/index.sass";

const itemsTypologies = [
  {
    "img": planoOne,
    "size": "170",
    "floor": "PRIMER PISO",
    "characterics": [
      "TRES DORMITORIOS",
      "DORMITORIO PRINCIPAL CON BAÑO PRIVADO Y VESTIDOR",
      "UN BAÑO COMPLETO ADICIONAL",
      "UN BAÑO SOCIAL",
      "SALA - COMEDOR, COCINA , LAVANDERÍA",
      "TERRAZA",
      "BAR",
      "SALA DE ESTART",
    ],
  },
  {
    "img": planoTwo,
    "size": "220",
    "floor": "Segundo PISO",
    "characterics": [
      "TRES DORMITORIOS",
      "DORMITORIO PRINCIPAL CON BAÑO PRIVADO Y VESTIDOR",
      "UN BAÑO COMPLETO ADICIONAL",
      "UN BAÑO SOCIAL",
      "SALA - COMEDOR, COCINA , LAVANDERÍA",
      "TERRAZA",
      "BAR",
      "SALA DE ESTART",
    ],
  },
]

class Typologies extends React.Component<any, any> {
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <button><FontAwesomeIcon icon={faCaretLeft} /></button>,
      nextArrow: <button><FontAwesomeIcon icon={faCaretRight} /></button>,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: "30px",
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: "0px",
          },
        },
        {
          breakpoint: 480,
          settings: {
            centerPadding: "0px",
          },
        },
      ],
    };

    return (
      <div className="cont-typologies">
        <div>
          <Slider {...settings}>
            {
              itemsTypologies.map(({img, floor, size, characterics}, index) =>
                <div className="item" key={index}>
                  <div className="cont-item">
                    <div className="cont-img-slide">
                      <img src={img} alt="" />
                    </div>
                    <div className="caption">
                      <h6>{floor}</h6>
                      <h2 className="title-caption">{size}m<sup>2</sup></h2>
                      <ul>
                        {characterics.map(characteristic =>
                          <li><span>{characteristic}</span></li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )
            }
          </Slider>
        </div>
      </div>
    );
  }
}

export default Typologies;