import React from "react";

import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Radium, { StyleRoot } from "radium";
import { fadeInRightBig } from "react-animations";

import markerLumina from "./../../assets/images/marker-lumina.png";
import "./sass/index.sass";

const styles = {
  fadeInRightBig: {
    animation: "x .5s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
  fadeInRightBig2: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
}

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -16.37061494017526,
  lng: -71.54512319829587,
};

class LocationPuertaDelSol extends React.Component<any, any> {
  render() {
    return (
      <StyleRoot>
        <div className="cont-location">
          <div className="flex">
            <div className="description" style={styles.fadeInRightBig}>
              <div className="cont-subtitle" >
                <FontAwesomeIcon icon={faMapMarkerAlt} className="marker" />
                <h5 className="subtitle">Cayma</h5>
              </div>
              <div className="text">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer.</p>
              </div>
            </div>
            <div className="cont-map">
              <div className="image-map" style={styles.fadeInRightBig2}>
                <LoadScript
                  googleMapsApiKey="AIzaSyCtkV9sSI34uz3NZURQUnwzCrK5C2wawxQ"
                >
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={18}
                  >
                    <Marker
                      icon={markerLumina}
                      position={center}
                    />
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </div>
        </div>
      </StyleRoot>
    );
  }
}

export default LocationPuertaDelSol;