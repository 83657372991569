import React from "react";

import Radium, { StyleRoot } from "radium";
import { fadeInRight } from "react-animations";
import ComponentPopover from "./../ComponentPopover/ComponentPopover";

import { ReactComponent as Trofeo } from "./../../assets/images/trofeo.svg";
import "./sass/index.sass";

const styles = {
  fadeInRight: {
    animation: "x 4.5s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
}

class OurTrajectory extends React.Component<any, any> {
  render() {
    return (
      <div className="cont-timeline">
        <div>
          <StyleRoot>
            <div style={styles.fadeInRight}>
              <ul className="top">
                <li>
                  <ComponentPopover anio="2009" description={<ul><li>+ Viviendas en Piedra Santa - Yanahuara</li><li>+ Remodelación Clínica COE - Umacollo</li></ul>} positionva="top" positionvt="bottom" idp="p-1" />
                </li>
                <li>
                  <ComponentPopover anio="2011" description={<ul><li>+ Picantería “Nuestra” - Umacollo</li><li>+ Terminal aduanero - Desaguadero, Puno</li><li>+ Vivienda Santa Patricia - Yanahuara</li></ul>} positionva="top" positionvt="bottom" idp="p-2" />
                </li>
                <li>
                  <ComponentPopover anio="2013" description={<ul><li>+ Residencial “VIA MARINA” - Mejia</li><li>+ Vivienda en “El Rosal “ - Cerro Colorado</li></ul>} positionva="top" positionvt="bottom" idp="p-3" />
                </li>
                <li>
                  <ComponentPopover anio="2015" description={<ul><li>+ Almacén industrial Granos Trading - Desaguadero, Puno</li><li>+ Estudio ventanas de alto rendimiento - FYNCIT - MP</li></ul>} positionva="top" positionvt="bottom" idp="p-4" />
                </li>
                <li>
                  <ComponentPopover anio="2017" description={<ul><li>+ “Torre Texao” - Umacollo</li><li>+ Taller Conforta Peru</li></ul>} positionva="top" positionvt="bottom" idp="p-5" />
                </li>
                <li>
                  <ComponentPopover anio="2019" description={<ul><li>+ Conjunto viviendas en Efigies - Cayma</li></ul>} positionva="top" positionvt="bottom" idp="p-6" />
                </li>
                <li>
                  <ComponentPopover anio="2021" description={<ul><li>+ Condominio Puerta del Sol - Cayma</li><li>+ Proyecto vivienda social - rural <br/> “Capsula de Vida” - CIENCIA ACTIVA - CONCITEC</li></ul>} positionva="top" positionvt="bottom" idp="p-7" />
                </li>
              </ul>
              <div className="line"></div>
              <ul className="bottom">
                <li>
                  <ComponentPopover anio="2010" description={<ul><li>+ Quinta residencial la Mansilla - Cayma</li><li>+ Planta rotomoldeo Nicoll - Arequipa</li><li>+ 3er puesto concurso internacional “GREEN 360”</li></ul>} positionva="bottom" positionvt="top" idp="p-8" />
                </li>
                <li>
                  <ComponentPopover anio="2012" description={<ul><li>+ “Peña del carajo” - Sachaca</li><li>+ Habilitación urbana “LAS EFIGIES” - Cayma</li></ul>} positionva="bottom" positionvt="top" idp="p-9" />
                </li>
                <li>
                  <ComponentPopover anio="2014" description={<ul><li>+ Residencial “PALERMO” - Yanahuara</li><li>+ Vivienda en “Bello Campo” - Cayma</li></ul>} positionva="bottom" positionvt="top" idp="p-10" /></li>
                <li>
                  <ComponentPopover anio="2016" description={<ul><li>+ Cerco terminal camiones <br/> MC transportes</li><li>+ Vivienda en Azores - Cerro Colorado</li></ul>} positionva="bottom" positionvt="top" idp="p-11" />
                </li>
                <li>
                  <ComponentPopover anio="2018" description={<ul><li>+ Residencial “Los Guindos”</li><li>+ Vivienda en Efigies - Cayma</li></ul>} positionva="bottom" positionvt="top" idp="p-12" /></li>
                <li>
                  <ComponentPopover anio="2020" description={<ul><li>+ Residencial LUMINA - Cayma</li><li>+ Estación Servicios <br/> Av. Tarapaca</li><li>+ Almacén Aceros Peruanos - Cerro Colorado</li></ul>} positionva="bottom" positionvt="top" idp="p-13" />
                </li>
                <li>
                  <ComponentPopover anio="2022" description="" positionva="bottom" positionvt="top" idp="p-14" />
                </li>
              </ul>
            </div>
          </StyleRoot>
        </div>
      </div>
    );

  }
}

export default OurTrajectory;