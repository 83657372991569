import React from "react";

import Media from "react-responsive";
import { NavLink } from "react-router-dom";

function NavigationUs() {
  return (
    <>
      <Media maxWidth="768px">
        <div className="left__menu">
          <ul>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/nosotros/vission-and-mission/"
              >
                <span className="name-project">Visión - Misión</span>
                <span className="subtitle">Pensamos en Grande</span>
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/nosotros/quienes-somos">
                <span className="name-project">¿Quiénes Somos?</span>
                <span className="subtitle">Conoce nuestra historia</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/nosotros/nuetros-pilares"
              >
                <span className="name-project">Nuestros Pilares</span>
                <span className="subtitle">
                  Apasionados por la construcción
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/nosotros/nuestra-trayectoria"
              >
                <span className="name-project">Nuestra Trayectoria</span>
                <span className="subtitle">Nos respalda LA EXPERIENCIA</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </Media>
    </>
  );
}

export default NavigationUs;
