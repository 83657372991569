import React from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radium, { StyleRoot } from "radium";
import { bounceInLeft } from "react-animations";
import Img from "react-image";

import logoLumina from "./../../assets/images/logo-lumina.png";
import "./sass/index.sass";

const styles = {
  bounceInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(bounceInLeft, "bounceInLeft"),
  },
}

class CoverLumina extends React.Component<any, any> {
  componentDidMount() {
    const path = window.location.pathname.split("/")[2];
    const pathNameProject = window.location.pathname.split("/").length > 3;
    const project = window.location.pathname.includes("/proyectos");
    const concept = window.location.pathname.includes("/concept");
    const coverProject = document.getElementById("cover");
    const bg = document.getElementById("img-background");
    const app = document.getElementById("app");
    const btn = document.getElementById("btn-iso");
    if (app) {
      app.classList.remove("lumina");
      app.classList.remove("via-maria");
      app.classList.remove("torre-texao");
      app.classList.remove("puerta-del-sol");
      app.classList.remove("ikiga");
      app.classList.add(path);
    }
    if (coverProject && project) {
      coverProject.classList.add(`cover-two`);
    }
    if (bg) {
      bg.classList.remove("lumina-cover");
      bg.classList.remove("via-maria-cover");
      bg.classList.remove("torre-texao-cover");
      bg.classList.remove("puerta-del-sol-cover");
      bg.classList.remove("ikiga-cover");
      bg.classList.add(`${path}-cover`);
      if (!pathNameProject) {
        bg.classList.remove("blur");
      }
    }
    if (!concept && coverProject) {
      coverProject.classList.remove(`cover-three`);
    }
    if (btn) {
      btn.style.display = "none";
    }
  };

  render() {
    return (
      <div className="cont-title-project">
        <StyleRoot>
          <div className="title-project">
            <div style={styles.bounceInLeft}>
              <Img src={logoLumina} loader={<div className="loader"><FontAwesomeIcon icon={faSpinner} pulse /></div>} alt=""/>
            </div>
          </div>
        </StyleRoot>
      </div>
    );
  }
}

export default CoverLumina;