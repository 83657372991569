import React from "react";

import Radium, { StyleRoot } from "radium";
import { fadeInRight } from "react-animations";

import people from "./../../assets/images/about-us.jpg";
import Carousel from "../../components/Carousel/CarouselRealEstate";
import "./sass/index.sass";

const styles = {
  fadeInRight: {
    animation: "x 3s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInRight2: {
    animation: "x 4s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
};

class AboutUs extends React.Component<any, any> {
  render() {
    return (
      <div className="cont-about">
        <StyleRoot>
          <h3 className="title" style={styles.fadeInRight}>
            INMOBILIARIA
          </h3>
          <Carousel />
        </StyleRoot>
      </div>
    );
  }
}

export default AboutUs;
