import { useState, useEffect } from "react";

import {
  faCaretLeft,
  faCaretRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radium, { StyleRoot } from "radium";
import { fadeInRightBig } from "react-animations";
import Img from "react-image";
import Slider from "react-slick";
import Carousel, { Modal, ModalGateway } from "react-images";
import Media, { useMediaQuery } from "react-responsive";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ikiga1 from "./../../assets/images2/realEstate/ikiga/img1.jpg";
import ikiga2 from "./../../assets/images2/realEstate/ikiga/img2.jpg";
import ikiga3 from "./../../assets/images2/realEstate/ikiga/img3.jpg";
import ikiga4 from "./../../assets/images2/realEstate/ikiga/img4.jpg";
import ikiga5 from "./../../assets/images2/realEstate/ikiga/img5.jpg";
import ikiga6 from "./../../assets/images2/realEstate/ikiga/img6.jpg";
import ikiga7 from "./../../assets/images2/realEstate/ikiga/img7.jpg";
import ikiga8 from "./../../assets/images2/realEstate/ikiga/img8.jpg";
import ikiga9 from "./../../assets/images2/realEstate/ikiga/img9.jpg";
import ikiga10 from "./../../assets/images2/realEstate/ikiga/img10.jpg";
import ikiga11 from "./../../assets/images2/realEstate/ikiga/img12.jpg";
import ikiga12 from "./../../assets/images2/realEstate/ikiga/img12.jpg";
import ikiga13 from "./../../assets/images2/realEstate/ikiga/img13.jpg";
import ikiga14 from "./../../assets/images2/realEstate/ikiga/img14.jpg";
import ikiga15 from "./../../assets/images2/realEstate/ikiga/img15.jpg";
import "./sass/index.sass";

const imagesIkiga = [
  ikiga1,
  ikiga2,
  ikiga3,
  ikiga4,
  ikiga5,
  ikiga6,
  ikiga7,
  ikiga8,
  ikiga9,
  ikiga10,
  ikiga11,
  ikiga12,
  ikiga13,
  ikiga14,
  ikiga15,
];

const widths = [
  540, 540, 540, 1400, 1400, 1400, 1100, 1280, 1500, 920, 950, 950, 800, 2000,
  1200,
];

const styles = {
  fadeInRightBig: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRightBig, "fadeInRightBig"),
  },
};

const Gallery = () => {
  const [itemsGallery, setItemsGallery] = useState<
    { img: string; width: string }[]
  >([]);
  const [itemsModal, setItemsModal] = useState<
    { source: string; id: number }[]
  >([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    const ratio = isMobile ? 0.27 : isTablet ? 0.55 : 0.69;
    const gallery = imagesIkiga.map((v, index) => {
      return {
        img: v,
        width: `${Math.floor(widths[index] * ratio)}px`,
      };
    });

    const modal = imagesIkiga.map((v, index) => {
      return {
        source: v,
        id: index,
      };
    });

    setItemsGallery(gallery);
    setItemsModal(modal);
  }, []);

  const settings = {
    className: "slider variable-width",
    centerMode: itemsGallery.length > 1 ? true : false,
    infinite: itemsGallery.length > 1 ? true : false,
    centerPadding: itemsGallery.length > 1 ? "75px" : "",
    slidesToShow: 1,
    speed: 1500,
    variableWidth: true,
    prevArrow: (
      <button>
        <FontAwesomeIcon icon={faCaretLeft} />
      </button>
    ),
    nextArrow: (
      <button>
        <FontAwesomeIcon icon={faCaretRight} />
      </button>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerPadding: itemsGallery.length > 1 ? "0px" : "",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: itemsGallery.length > 1 ? "0px" : "",
        },
      },
    ],
  };

  return (
    <StyleRoot>
      <div className="cont-about">
        <div className="cont-gallery" style={styles.fadeInRightBig}>
          <Slider {...settings}>
            {itemsGallery.map(({ img, width }, index) => (
              <div
                className="item"
                key={index}
                style={{ width: width }}
                onClick={() => {
                  setCurrentIndex(index);
                  setModalIsOpen(true);
                }}
              >
                <div className="cont-img-slide">
                  <Img
                    src={img}
                    alt=""
                    loader={
                      <div className="loader-image">
                        <FontAwesomeIcon icon={faSpinner} pulse />
                      </div>
                    }
                  />
                </div>
              </div>
            ))}
          </Slider>
          <Media maxWidth="768px">
            <ModalGateway>
              {modalIsOpen ? (
                <Modal onClose={() => setModalIsOpen(false)}>
                  <Carousel views={itemsModal} currentIndex={currentIndex} />
                </Modal>
              ) : null}
            </ModalGateway>
          </Media>
        </div>
      </div>
    </StyleRoot>
  );
};

export default Gallery;
