import React from "react";

import { Link } from "react-router-dom";

import "./sass/style.sass";

class ThankyouPage extends React.Component<any, any> {
  render() {
    return (
      <div className="wraper wraper-thak-you">
        <div className="cont-thak-you">
            <div>
              <h4>GRACIAS POR ESCRIBIRNOS</h4>
              <p>Nos pondremos en contacto contigo lo antes posible.</p>
              <Link to="/" className="btn">REGRESAR</Link>
            </div>
        </div>
      </div>
    );
  }
}

export default ThankyouPage;