import React from "react";

import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

import "./sass/index.sass";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: 0,
    overflow: "initial",
    background: "transparent",
    borderRadius: 0,
    maxWidth: "13rem",
    boxShadow: 'none'
  },
  contPopover: {
    position: "relative",
    padding: "1rem 1rem",
    [theme.breakpoints.down('sm')]: {
      padding: "0rem",
      maxWidth: "6.5rem",
      fontSize: "small",
    }
  },
  contIconPopover: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textPopover: {
    margin: 0,
    color: "var(--theme-page-title)",
    textAlign: "center",
  },
}));

function ComponentPopover(props: any) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const mobile = window.screen.width < 600;

  const mobileCond = (val) => {
    if (val === "top") {
      return "left"
    }else{
      return "right"
    }
  };

  const open = Boolean(anchorEl);
  
  return (
    <>
      <button aria-owns={open ? "mouse-over-popover" : undefined} aria-haspopup="true" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {props.anio}
      </button>
      {props.description && props.description ?
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          //conditions for mobile popover
          anchorOrigin={{
            vertical: mobile? "center" : props.positionva,
            horizontal: mobile? mobileCond(props.positionva) : "center",
          }}
          transformOrigin={{
            vertical: mobile? "center" : props.positionvt,
            horizontal: mobile? mobileCond(props.positionvt) : "center",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          TransitionProps={{
            timeout : 2000
          }}
        >
          <div className={`${classes.contPopover} ${props.positionva}`}>
            <div className={`box-icon ${classes.contIconPopover}`}>
              {props.icon}
            </div>
            <div className={`${classes.textPopover} textPopover`}>{props.description}</div>
          </div>
        </Popover>
        : ""}
    </>
  );

}

export default ComponentPopover;