import React, { useEffect, useRef } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any, hideMenu: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: { target: any; }) {
      if (ref.current && !ref.current.contains(event.target)) {
        hideMenu()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, hideMenu]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props: any) {

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.hideMenu);

  return <div ref={wrapperRef}>{props.children}</div>;
}


export default OutsideAlerter;
