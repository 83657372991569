import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import { Popover } from "@material-ui/core";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BtnInmobiliaria: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className="inmo"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      style={{ marginTop: "-10px", marginRight: "25px" }}
    >
      <NavLink
        activeClassName="active-sublink"
        to="/servicios/inmobiliaria"
        style={{
          color: "#0aa7c6",
          animation: "beat .9s infinite alternate",
        }}
      >
        <FontAwesomeIcon icon={faCircle} size="2x" />
      </NavLink>
      <Popover
        id="mouse-over-popover"
        style={{
          pointerEvents: "none",
          marginTop: "10px",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={0}
      >
        <span style={{ color: "#0aa7c6", fontWeight: "bold" }}>
          Proyecto en Venta
        </span>
      </Popover>
    </div>
  );
};

export default BtnInmobiliaria;
