
import React, { useState } from "react";

import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./sass/index.sass";

function DarkButton (props) {
  const [isLight, setIsLight] = useState(true);

  const handleClick = () => {
    setIsLight((isLight) => !isLight)
    props.setIsLight();
  }

  if(isLight){
    document.body.classList.remove("dark");
    document.body.classList.add("light")
  }else{
    document.body.classList.remove("light")
    document.body.classList.add("dark");
  }

  return (
    <button onClick={handleClick} className="change-mode">
      {isLight ? <FontAwesomeIcon icon={faSun} /> : <FontAwesomeIcon icon={faMoon} />}
    </button>
  );
};

export default DarkButton;