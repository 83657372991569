import React from "react";

import Radium, { StyleRoot } from "radium";
import { fadeInRight } from "react-animations";

import Carousel from "../../components/Carousel/CarouselConstruction";
import "./sass/index.sass";


const styles = {
  fadeInRight: {
    animation: "x 3s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInRight2: {
    animation: "x 4s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
}

class Construction extends React.Component<any, any> {
  render() {
    return (
      <div className="cont-about">
        <StyleRoot>
          <h3 className="title" style={styles.fadeInRight}>CONSTRUCCIÓN</h3>
          <div className="cont-text" style={styles.fadeInRight2}>
            <p>25000 m<sup>2</sup> construidos. 7 proyectos inmobiliarios concluidos. Construcción de Almacenes, comercios y vivienda</p>
          </div>
          <Carousel/>
        </StyleRoot>
      </div>
    );
  }
}

export default Construction;