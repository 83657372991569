import casasMansilla1 from "./../../assets/images2/construction/CasasMansilla/img1.jpg";
import casasMansilla2 from "./../../assets/images2/construction/CasasMansilla/img2.jpg";
import casasMansilla3 from "./../../assets/images2/construction/CasasMansilla/img3.jpg";
import casasMansilla4 from "./../../assets/images2/construction/CasasMansilla/img4.jpg";
import casasMansilla5 from "./../../assets/images2/construction/CasasMansilla/img5.jpg";
import casasMansilla6 from "./../../assets/images2/construction/CasasMansilla/img6.jpg";
import casasMansilla7 from "./../../assets/images2/construction/CasasMansilla/img7.jpg";

import casasPiedraSanta1 from "./../../assets/images2/construction/CasasPiedraSanta/img1.jpg";
import casasPiedraSanta2 from "./../../assets/images2/construction/CasasPiedraSanta/img2.jpg";
import casasPiedraSanta3 from "./../../assets/images2/construction/CasasPiedraSanta/img3.jpg";
import casasPiedraSanta4 from "./../../assets/images2/construction/CasasPiedraSanta/img4.jpg";
import casasPiedraSanta5 from "./../../assets/images2/construction/CasasPiedraSanta/img5.jpg";
import casasPiedraSanta6 from "./../../assets/images2/construction/CasasPiedraSanta/img6.jpg";

import puertaDelSol1 from "./../../assets/images2/construction/PuertaDelSol/img1.jpg";
import puertaDelSol2 from "./../../assets/images2/construction/PuertaDelSol/img2.jpg";
import puertaDelSol3 from "./../../assets/images2/construction/PuertaDelSol/img3.jpg";
import puertaDelSol4 from "./../../assets/images2/construction/PuertaDelSol/img4.jpg";
import puertaDelSol5 from "./../../assets/images2/construction/PuertaDelSol/img5.jpg";
import puertaDelSol6 from "./../../assets/images2/construction/PuertaDelSol/img6.jpg";
import puertaDelSol7 from "./../../assets/images2/construction/PuertaDelSol/img7.jpg";
import puertaDelSol8 from "./../../assets/images2/construction/PuertaDelSol/img8.jpg";
import puertaDelSol9 from "./../../assets/images2/construction/PuertaDelSol/img9.jpg";

import estacionServicio1 from "./../../assets/images2/construction/EstacionServicio/img1.jpg";
import estacionServicio2 from "./../../assets/images2/construction/EstacionServicio/img2.jpg";
import estacionServicio3 from "./../../assets/images2/construction/EstacionServicio/img3.jpg";
import estacionServicio4 from "./../../assets/images2/construction/EstacionServicio/img4.jpg";

const itemsGallery = [
  {
    id: 0,
    img: casasMansilla3,
    title: "Casas las Mansilla",
    description: "Construcción de 5 viviendas en un terreno de 520 m2. Area de construcción 880.00 m2.",
    photos: [
        {
            source: casasMansilla1
        },
        {
            source: casasMansilla2
        },
        {
            source: casasMansilla3
        },
        {
            source: casasMansilla4
        },
        {
            source: casasMansilla5
        },
        {
            source: casasMansilla6
        },
        {
            source: casasMansilla7
        }
    ],
  },
  {
    id: 1,
    img: casasPiedraSanta6,
    title: "Casas Piedra Santa",
    description: "Construcción de 3 viviendas en un área de 325 m2. Area total de construcción 550.00 m2.",
    photos: [
        {
            source: casasPiedraSanta1
        },
        {
            source: casasPiedraSanta2
        },
        {
            source: casasPiedraSanta3
        },
        {
            source: casasPiedraSanta4
        },
        {
            source: casasPiedraSanta5
        },
        {
            source: casasPiedraSanta6
        },
    ],
  },
  {
    id: 2,
    img: puertaDelSol6,
    title: "Puerta Del Sol",
    description: "Construcción resindencial de 10 unidades de vivienda. Area de construcción 2 120.00 m2",
    photos: [
        {
            source: puertaDelSol6
        },
        {
            source: puertaDelSol5
        },
        {
            source: puertaDelSol4
        },
        {
            source: puertaDelSol3
        },
        {
            source: puertaDelSol2
        },
        {
            source: puertaDelSol1
        },
        {
            source: puertaDelSol7
        },
        {
            source: puertaDelSol8
        },
        {
            source: puertaDelSol9
        },
    ],
  },
  {
    id: 3,
    img: estacionServicio4,
    title: "Estación de Servicio",
    description: "Construcción de una estación de servicios completa. Area de intervención 364.00 m2",
    photos: [
        {
            source: estacionServicio1
        },
        {
            source: estacionServicio2
        },
        {
            source: estacionServicio3
        },
        {
            source: estacionServicio4
        },
    ],
  },
]

export default itemsGallery;