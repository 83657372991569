import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import OutsideAlerter from "../OutsideClick/OutsideClick";
import Media from "react-responsive";

import "./sass/index.sass";

function Menu() {
  const [openNav, setOpen] = useState(false);

  const openMenu = () => {
    setOpen((openNav) => !openNav);
  };

  const hideMenu = () => {
    setOpen(false);
  };

  return (
    <OutsideAlerter hideMenu={hideMenu}>
      <div className="menu">
        <div className="btn-menu">
          <button
            className={`preloader-chasing-squares ${
              openNav ? "active-btn" : ""
            }`}
            onClick={openMenu}
          >
            {/* <div className="square"></div>
            <div className="square"></div>
            <div className="square"></div>
            <div className="square"></div> */}
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>
        </div>
        <div className={`nav ${openNav ? "active" : ""}`}>
          <ul>
            <li className="link">
              <Media maxWidth="768px">
                <NavLink
                  activeClassName="active-link"
                  onClick={hideMenu}
                  to="/nosotros"
                >
                  NOSOTROS
                </NavLink>
              </Media>
              <Media minWidth="769px">
                {" "}
                <NavLink
                  activeClassName="active-link"
                  onClick={hideMenu}
                  to="/nosotros/vission-and-mission/"
                >
                  NOSOTROS
                </NavLink>
              </Media>
            </li>
            <li className="link">
              <NavLink onClick={hideMenu} to="/servicios/arquitectura">
                SERVICIOS
              </NavLink>
              <ul className="content-sublinks NavLinks">
                <li className="sub-link">
                  <NavLink
                    onClick={hideMenu}
                    activeClassName="active-sublink"
                    to="/servicios/arquitectura"
                  >
                    Arquitectura
                  </NavLink>
                </li>
                <li className="sub-link">
                  <NavLink
                    onClick={hideMenu}
                    activeClassName="active-sublink"
                    to="/servicios/construccion"
                  >
                    Construcción
                  </NavLink>
                </li>
                <li className="sub-link">
                  <NavLink
                    onClick={hideMenu}
                    activeClassName="active-sublink"
                    to="/servicios/inmobiliaria"
                  >
                    Inmobiliaria
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="link">
              <NavLink
                onClick={hideMenu}
                activeClassName="active-link"
                to="/contacto"
              >
                CONTACTO
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </OutsideAlerter>
  );
}

export default Menu;
