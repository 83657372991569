import React from "react";

import GalleryLumina from "../../components/Gallery/GalleryLumina";
import GalleryPuertaDelSol from "../../components/Gallery/GalleryPuertaDelSol";
import GalleryTorreTexao from "../../components/Gallery/GalleryTorreTexao";
import GalleryViaMaria from "../../components/Gallery/GalleryViaMaria";
import GalleryIkiga from "../../components/Gallery/GalleryIkiga";
import LateralNavProjects from "../../components/LateralNavProjects/LateralNavProjects";

class GalleryProjectPage extends React.Component<any, any> {
  componentDidMount() {
    const path = window.location.pathname.split("/")[2];
    const pathNameProject = window.location.pathname.split("/").length > 3;
    const project = window.location.pathname.includes("/proyectos");
    const concept = window.location.pathname.includes("/concept");
    const coverProject = document.getElementById("cover");
    const bg = document.getElementById("img-background");
    const btn = document.getElementById("btn-iso");
    if (coverProject && project) {
      coverProject.classList.add(`cover-two`);
    }
    if (bg) {
      bg.classList.add(`${path}-cover`);
      if(pathNameProject){
        bg.classList.add("blur");
      }
    }
    if (!concept && coverProject) {
      coverProject.classList.remove(`cover-three`);
    }
    if (btn) {
      btn.style.display = "none";
    }
  }
  
  render() {
    return (
      <div className="wraper wraper-two">
        <div className="item left">
          <LateralNavProjects />
        </div>
        <div className="item right">
          <div className="right__cont">
            {window.location.pathname.includes("/lumina") ?
              <GalleryLumina />
              : window.location.pathname.includes("/via-maria") ?
                <GalleryViaMaria />
                : window.location.pathname.includes("/torre-texao") ?
                  <GalleryTorreTexao />
                  : window.location.pathname.includes("/puerta-del-sol") ?
                    <GalleryPuertaDelSol />
                    : window.location.pathname.includes("/ikiga") ?
                      <GalleryIkiga /> : ""
            }
          </div>
        </div>
      </div>
    );
  }
}

export default GalleryProjectPage;